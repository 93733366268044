define("discourse/plugins/discourse-ratings/discourse/components/rating-star", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("rating"), (_obj = {
    tagName: "input",
    disabled: (0, _computed.not)("enabled"),
    attributeBindings: ["value", "checked:checked", "disabled:disabled"],
    willInsertElement() {
      this._super(...arguments);
      this.element.type = "radio";
    },
    didRender() {
      this._super(...arguments);
      // For IE support
      this.element.value = this.value;
    },
    click() {
      this.set("rating", this.element.value);
    },
    checked(rating) {
      return this.get("value") <= rating;
    }
  }, (_applyDecoratedDescriptor(_obj, "checked", [_dec], Object.getOwnPropertyDescriptor(_obj, "checked"), _obj)), _obj)));
});