define("discourse/plugins/discourse-ratings/discourse/components/rating-destroy", ["exports", "@ember/component", "discourse-common/utils/decorators", "discourse/plugins/discourse-ratings/discourse/models/rating"], function (_exports, _component, _decorators, _rating) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("categoryId", "type"), (_obj = {
    classNames: ["admin-ratings-destroy", "rating-action"],
    destroyDisabled(categoryId, type) {
      return [categoryId, type].any(i => !i);
    },
    actions: {
      destroyRatings() {
        let data = {
          category_id: this.categoryId
        };
        this.set("startingDestroy", true);
        _rating.default.destroy(this.type, data).then(result => {
          if (result.success) {
            this.set("destroyMessage", "admin.ratings.destroy.started");
          } else {
            this.set("destroyMessage", "admin.ratings.error.destroy_failed_to_start");
          }
        }).finally(() => this.set("startingDestroy", false));
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "destroyDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "destroyDisabled"), _obj)), _obj)));
});