define("discourse/plugins/discourse-ratings/discourse/components/rating-type", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators"], function (_exports, _component, _computed, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const minTypeLength = 2;
  const minNameLength = 2;
  const noneType = "none";
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("invalidName", "type.name", "currentName"), (_obj = {
    tagName: "tr",
    classNameBindings: [":rating-type", ":admin-ratings-list-object", "hasError"],
    invalidType: (0, _computed.lt)("type.type.length", minTypeLength),
    invalidName: (0, _computed.lt)("type.name.length", minNameLength),
    addDisabled: (0, _computed.or)("invalidType", "invalidName"),
    noneType: (0, _computed.equal)("type.type", noneType),
    showControls: (0, _computed.not)("noneType"),
    didReceiveAttrs() {
      this._super();
      this.set("currentName", this.type.name);
    },
    updateDisabled(invalidName, name, currentName) {
      return invalidName || name === currentName;
    }
  }, (_applyDecoratedDescriptor(_obj, "updateDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "updateDisabled"), _obj)), _obj)));
});