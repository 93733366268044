define("discourse/plugins/discourse-ratings/discourse/components/rating-object-list", ["exports", "@ember/component", "@ember/object/computed", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-ratings/discourse/models/rating-object"], function (_exports, _component, _computed, _decorators, _I18n, _ratingObject) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("objectType"), _dec2 = (0, _decorators.default)("objectType"), _dec3 = (0, _decorators.default)("objectType"), (_obj = {
    classNameBindings: [":object-types", ":admin-ratings-list", "objectType"],
    hasObjects: (0, _computed.notEmpty)("objects"),
    title(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.title`);
    },
    nameLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.name`);
    },
    noneLabel(objectType) {
      return _I18n.default.t(`admin.ratings.${objectType}.none`);
    },
    actions: {
      newObject() {
        this.get("objects").pushObject({
          name: "",
          types: [],
          isNew: true
        });
      },
      addObject(obj) {
        let data = {
          name: obj.name,
          types: obj.types,
          type: this.objectType
        };
        this.set("loading", true);
        _ratingObject.default.add(data).then(result => {
          if (result.success) {
            this.refresh();
          } else {
            obj.set("hasError", true);
          }
          this.set("loading", false);
        });
      },
      updateObject(obj) {
        let data = {
          name: obj.name,
          types: obj.types
        };
        this.set("loading", true);
        _ratingObject.default.update(this.objectType, data).then(result => {
          if (result.success) {
            this.refresh();
          } else {
            obj.set("hasError", true);
          }
          this.set("loading", false);
        });
      },
      destroyObject(obj) {
        if (obj.isNew) {
          this.get("objects").removeObject(obj);
        } else {
          let data = {
            name: obj.name
          };
          this.set("loading", true);
          _ratingObject.default.destroy(this.objectType, data).then(result => {
            if (result.success) {
              this.refresh();
            } else {
              obj.set("hasError", true);
            }
            this.set("loading", false);
          });
        }
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "nameLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "nameLabel"), _obj), _applyDecoratedDescriptor(_obj, "noneLabel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "noneLabel"), _obj)), _obj)));
});